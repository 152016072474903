.body {
  width: 50vw;
  margin-left: 25vw;
  margin-right: 25vw;
  color: rgb(211, 238, 223);
}

a {
  color: white;
  font-weight: 800;
}

/* Responsive version */
@media only screen and (max-width: 800px) {
  .body {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
