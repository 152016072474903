.Survey {
  font-size: 1.2rem;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: -100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  overflow-x: hidden;
  z-index: 200;
}

.Survey .survey-content {
  padding: 10vw;
}

.Survey .bottomButtons {
  text-align: right;
  margin-top: 3rem;
}

.Survey .error {
  color: red;
  font-weight: bold;
}

.Survey .question {
  margin-bottom: 1rem;
  background-color: rgb(196, 196, 196, 0.1);
  padding: 1rem;
  border: 1px solid #999;
}

.Survey .question h4 {
  margin-top: 0;
}
