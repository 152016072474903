.BoxedImage {
  text-align: center;
  background-color: transparent;
  z-index: -10;
}

.BoxedImage img {
  position: relative;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
