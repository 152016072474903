.Footer {
  text-align: center;
  font-size: 1rem;
  margin: 4rem;
  opacity: 0.5;
}

.Footer span {
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.8);
}
