.ShareButtons_container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ShareButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ShareButtons.small-buttons {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 3rem;
}
