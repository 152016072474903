.ShareButton {
  text-align: center;
}

.ShareButton .img-container {
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

.ShareButton .img-container.with-background img {
  padding-left: 5%;
  width: 90%;
}

.ShareButton .img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.ShareButton a {
  text-decoration: none;
}

.ShareButtons.small-buttons .img-container {
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

.ShareButtons.small-buttons p {
  font-size: 50%;
}
