.SurveyInput input {
  box-sizing: border-box;
  font-size: 1.2rem;
  border: 1px solid white;
  padding: 0.5rem;
  background-color: black;
  color: white;
  font-family: "Oswald", "Helvetica Neue", sans-serif;
}

.SurveyInput input::placeholder {
  opacity: 0.5;
}

.SurveyInput .error {
  color: red;
  font-weight: bold;
  margin-top: 0;
}

@media only screen and (max-width: 800px) {
  .SurveyInput input {
    width: 100% !important;
  }
}
