div.EmailBar {
  display: inline-block;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0.1rem solid white;
}

div.EmailBar input {
  padding: 0.5rem;
  font-size: 1.25rem;
  color: rgb(90, 138, 111);
  border: none;
  background-color: none;
  border-radius: 0.5rem;
  font-family: "Oswald", "Helvetica Neue", sans-serif;
}

div.EmailBar input::placeholder {
  opacity: 0.5;
}

div.EmailBar button {
  padding: 0.5rem;
  font-size: 1.25rem;
  background-color: rgb(90, 138, 111);
  color: white;
  border: none;
  font-family: "Oswald", "Helvetica Neue", sans-serif;
}

div.EmailBar button:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: rgb(60, 82, 69);
}

div.EmailBar button:hover {
  background-color: black;
}

/* Responsive version */
@media only screen and (max-width: 800px) {
  div.EmailBar input {
    text-align: center;
    display: block;
  }
  div.EmailBar button {
    width: 33%;
    border-bottom-left-radius: 0.5rem;
  }
  div.EmailBar button:last-child {
    width: 67%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
