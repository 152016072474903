.ImageRow {
  display: flex;
  flex-direction: row;
  /* padding-top: 1rem;
  padding-bottom: 0; */
  align-items: center;
  z-index: -10;
  margin-top: 1.5rem;
}

.ImageRow .imageContainer {
  /* width: 25%;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  margin-left: -2rem;
  margin-right: -2rem; */
  overflow: hidden;
}

/* .ImageRow .imageContainer:first-child {
  padding-left: 0;
}

.ImageRow .imageContainer:last-child {
  padding-right: 0;
} */

.ImageRow .image {
  width: 25vw;
  min-width: 25vw;
}
