/* .Background .background {
} */

.Background .overlay {
  z-index: 100;
}

.Background .leftImages .BoxedImage {
  position: absolute;
  left: 0;
  width: 20vw;
  height: 30vh;
}

.Background .rightImages .BoxedImage {
  position: absolute;
  right: 0;
  width: 20vw;
  height: 30vh;
}
