.header {
  padding: 2rem 25%;
  margin-top: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.header h1 {
  margin: 0;
  font-size: 4rem;
  font-weight: 200;
  text-transform: uppercase;
}

.header img.bars {
  display: inline-block;
  height: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  opacity: 0.2;
}

.header h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 200;
  text-transform: uppercase;
  color: rgb(211, 238, 223);
}

.header h2 .exclusive {
  color: white;
}

/* Responsive version */
@media only screen and (max-width: 600px) {
  .header {
    padding: 2rem 1rem;
  }
  .header h1 {
    font-size: 12vw;
  }

  .header h2 {
    font-size: 5vw;
  }

  .header img.bars {
    height: 12vw;
  }
}
