.Button {
  border: 1px solid white;
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.Button.selected {
  background-color: white;
  color: black;
}
